import React, { memo, useState } from 'react';
import moment, { Moment } from 'moment';
import { SpotButton, SpotTextInput, SpotDatePicker, SpotModal, SpotModalControlProps } from '@enterprise/spot';
import { FormApi } from '@enterprise/common';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './ScheduledItemSaveModal.module.scss';
import classNames from 'classnames';
import { IconButton } from '@mui/material';

interface ScheduledItemSaveModalProps {
    defaultName?: string;
    invalid?: boolean;
    form?: FormApi;
    onSave?: (params: ScheduledItemSaveDetail) => void;
    title: string;
    noButton?: boolean;
    onClose?: any;
    className?: string;
    disabled?: boolean;
}

export interface ScheduledItemSaveDetail {
    date: Date;
    name?: string;
}

export const ScheduledItemSaveModal = memo(function ScheduledItemSaveModal(props: ScheduledItemSaveModalProps) {
    const { title, noButton, onClose, onSave, form, invalid, defaultName, className, disabled } = props;
    const [updateName, setUpdateName] = useState<string>();
    const [updateAt, setUpdateAt] = useState<Date | undefined>(moment().add(1, 'hour').toDate());
    const [openDP, setOpenDP] = useState<boolean>(false);

    const renderSaveModalController = ({ open }: SpotModalControlProps) => {
        const handleClick = function () {
            if (form) {
                form.change('validateOnly', true);
                // need to add setTimeout because async validation should be fired after 'validateOnly' form value change
                setTimeout(() => {
                    form.submit()?.then(() => {
                        if (!invalid) {
                            open();
                        }
                    });
                });
            } else {
                open();
            }
        };

        return (
            <SpotButton disabled={disabled} isPrimary={true} onClick={handleClick} data-automation-id="schedule-item-button-start">
                Schedule
            </SpotButton>
        );
    };

    const onSaveWithSchedulerClicked = (close) => {
        onSave?.({ date: moment(updateAt).toDate(), name: updateName || defaultName });
        setTimeout(close, 500);
    };

    return (
        <SpotModal
            className={className}
            title={title}
            isVisible={noButton}
            controller={noButton ? undefined : renderSaveModalController}
            onHide={noButton ? onClose : undefined}
        >
            {({ close }) => (
                <div>
                    <div>
                        <SpotTextInput onChange={({ target }) => setUpdateName(target.value)} value={updateName || defaultName} placeholder="Name" />
                        <span className={classNames('spot-typography__text--secondary', styles.datePicker)}>
                            <span>
                                Changes will be applied at <strong>{moment(updateAt).format('LLL')}</strong>
                            </span>
                            <SpotDatePicker
                                onChange={(date?: Moment) => setUpdateAt(date?.toDate())}
                                value={updateAt}
                                onClose={() => {
                                    setOpenDP(false);
                                }}
                                onOpen={() => setOpenDP(true)}
                                open={openDP}
                                minDateTime={moment()}
                                renderInput={({ inputRef }) => (
                                    <div ref={inputRef}>
                                        <IconButton
                                            classes={{ root: styles.datePickerButton }}
                                            aria-label="open date time picker"
                                            onClick={() => setOpenDP(true)}
                                        >
                                            <KeyboardArrowDownIcon />
                                        </IconButton>
                                    </div>
                                )}
                            />
                        </span>
                    </div>
                    <div>
                        <span style={{ width: '50%', display: 'inline-block' }}>
                            <a onClick={noButton ? onClose : close}>Cancel</a>
                        </span>
                        <span
                            style={{
                                width: '50%',
                                display: 'inline-block',
                                textAlign: 'right',
                            }}
                        >
                            <SpotButton
                                disabled={disabled}
                                onClick={() => onSaveWithSchedulerClicked(noButton ? onClose : close)}
                                data-automation-id="schedule-item-button-finish"
                            >
                                Schedule
                            </SpotButton>
                        </span>
                    </div>
                </div>
            )}
        </SpotModal>
    );
});
