import { Service } from 'typedi';
import { makeAutoObservable, toJS } from 'mobx';
import { ChildrenTasksQuery, TaskService, TasksQuery } from '../../../services/TaskService';
import { Notifications } from '../../Notifications';
import { ParentTask, TasksPaginatedResponse } from '../../../core/entity/TaskEntity';
import { OrganizationService } from '../../../services/OrganizationService';
import { NotificationService } from '../../../services/NotificationService';
import { CommandIntervalWorker } from '../../../services/workers/CommandIntervalWorker';
import { TaskCommand } from '../../../services/workers/TaskCommand';
import { findIndex, isEqual } from 'lodash';
import { TaskStatus } from '../../../core/enums/TaskStatus';
import { InvoiceItemService } from '../../../services/InvoiceItemService';
import AppStore from '../../../store/AppStore';

export interface TasksItemsState {
    grid: {
        pageSize?: number;
        sortModel?: {
            field: string;
            sort: 'asc' | 'desc' | null | undefined;
        }[];
    };
    details: {
        pageSize?: number;
    };
}

export interface TasksGridState {
    pageSize?: number;
    page?: number;
    sortModel?: {
        field: string;
        sort: 'asc' | 'desc' | null | undefined;
    }[];
}

@Service()
export class ActivityPageStore {
    isLoaded: boolean = false;

    private _taskLoadingTimeout: unknown;
    private _parentTasks?: TasksPaginatedResponse;
    private _detailedTask?: ParentTask;

    private _isSyncPending: boolean = false;
    private _page?: number;
    private _cancelInProgress: Record<string, boolean> = {};
    private _runFailedTasksRequest: Record<string, boolean> = {};

    private _detailsPage?: number;

    applications: any[] = [];

    get isTaskLoading(): boolean {
        return Boolean(this._taskLoadingTimeout);
    }

    get parentTasks(): TasksPaginatedResponse | undefined {
        return toJS(this._parentTasks);
    }

    get detailedTask(): ParentTask | undefined {
        return toJS(this._detailedTask);
    }

    get isSyncPending(): boolean {
        return toJS(this._isSyncPending);
    }

    get cancelInProgress(): Record<string, boolean> {
        return toJS(this._cancelInProgress);
    }

    get runFailedTasksRequest(): Record<string, boolean> {
        return toJS(this._runFailedTasksRequest);
    }

    get gridState(): TasksGridState | undefined {
        return {
            ...toJS(this.appStore.userState?.tasks?.grid),
            page: toJS(this._page),
        };
    }

    get detailsState(): TasksGridState | undefined {
        return {
            ...toJS(this.appStore.userState?.tasks?.details),
            page: toJS(this._detailsPage),
        };
    }

    constructor(
        private readonly service: TaskService,
        private readonly orgService: OrganizationService,
        private readonly notificationService: NotificationService,
        private readonly itemService: InvoiceItemService,
        private appStore: AppStore,
    ) {
        makeAutoObservable(this);
    }

    updateGridState = (state: TasksGridState) => {
        this._page = state.page;

        const currentState = toJS(this.appStore.userState?.tasks);
        if (isEqual(currentState?.grid, state)) {
            return;
        }

        const newGridState = { tasks: { ...currentState, grid: state } as TasksItemsState };
        this.appStore.updateUserState(newGridState);
    };

    updateDetailsState = (state: TasksGridState) => {
        this._detailsPage = state.page;

        const currentState = toJS(this.appStore.userState?.tasks);
        if (isEqual(currentState?.details, state)) {
            return;
        }

        const newGridState = { tasks: { ...currentState, details: state } as TasksItemsState };
        this.appStore.updateUserState(newGridState);
    };

    async load() {
        await this.fetchOrgApplications();
        this.isLoaded = true;
    }

    async fetchParentTasks(query: TasksQuery, { delay = 0 } = {}): Promise<void> {
        clearTimeout(this._taskLoadingTimeout as number);

        const timeout = setTimeout(async () => {
            try {
                const parentTasks = await this.service.getParentTasks(query);

                if (this._taskLoadingTimeout === timeout) {
                    this._parentTasks = parentTasks;
                    this._isSyncPending = this._parentTasks.tasks.some(
                        (task) =>
                            (/Syncing \w+/gi.test(task.name) || task.name === 'Site Sync') &&
                            [TaskStatus.PENDING, TaskStatus.RUNNING].includes(task.status),
                    );
                    void this.getNotifications(query);
                }
            } finally {
                if (this._taskLoadingTimeout === timeout) {
                    this._taskLoadingTimeout = undefined;
                }
            }
        }, delay);

        this._taskLoadingTimeout = timeout;
    }

    async syncChangedProducts(): Promise<void> {
        this._isSyncPending = true;
        await this.itemService.updateChangedProducts();
    }

    async getNotifications(query: TasksQuery): Promise<void> {
        if (this.notificationService.hasWorker('tasks')) {
            this.notificationService.removeAllListeners('tasks_tick');
            this.notificationService.stopWorker('tasks');
        }

        this.notificationService.runWorker('tasks', new CommandIntervalWorker(120000, new TaskCommand({ query }), false));
        this.notificationService.on('tasks_tick', (updates: ParentTask[]) => {
            this._parentTasks = this.parentTasks && {
                ...this.parentTasks,
                tasks: this.parentTasks.tasks.map((item) => {
                    return updates.find(({ id }) => id === item.id) || item;
                }),
            };
        });
    }

    async fetchDetailedTask(query: ChildrenTasksQuery): Promise<void> {
        this._detailedTask = undefined;
        this._detailedTask = await this.service.getDetailedTask(query);
    }

    clearDetailedTask(): void {
        this._detailedTask = undefined;
        this._detailsPage = undefined;
    }

    async runAllFailedChildTasks(parentTaskId: number): Promise<void> {
        try {
            this._runFailedTasksRequest = { ...this.runFailedTasksRequest, [parentTaskId]: true };
            const taskIds = await this.service.runFailedTasks(parentTaskId);

            await this.updateTasks(taskIds);
            Notifications.success(`Scheduled ${taskIds.length} task(s) for execution`);
        } finally {
            this._runFailedTasksRequest = { ...this.runFailedTasksRequest, [parentTaskId]: false };
        }
    }

    async runTasks(ids: number[]): Promise<void> {
        await this.service.runTasks(ids);
        await this.updateTasks(ids);
        Notifications.success(`Scheduled ${ids.length} task(s) for execution`);
    }

    private async updateTasks(ids: number[]): Promise<void> {
        const tasks = await this.service.getParentTasks();
        const updatedTasks = this.parentTasks?.tasks && [...this.parentTasks?.tasks];

        tasks.tasks.forEach((item) => {
            const index = findIndex(updatedTasks, ({ id }) => id === item.id);
            updatedTasks?.splice(index, 1, item);
        });

        this._parentTasks = this.parentTasks && updatedTasks && { ...this.parentTasks, tasks: updatedTasks };
    }

    async cancelTasks(ids: number[]): Promise<void> {
        try {
            this._cancelInProgress = ids.reduce((agg, id) => ({ ...agg, [id]: true }), this.cancelInProgress);
            await this.service.cancelTasks(ids);
            await this.updateTasks(ids);
        } catch (error) {
            Notifications.error(error.message);
            await this.updateTasks(ids);
        } finally {
            this._cancelInProgress = ids.reduce((agg, id) => ({ ...agg, [id]: false }), this.cancelInProgress);
        }
    }

    async fetchOrgApplications(): Promise<any> {
        this.applications = await this.orgService.getApplications();
    }

    clearTasks() {
        this._parentTasks = undefined;
        this._detailedTask = undefined;
        this._page = undefined;
        this.notificationService.removeAllListeners('tasks_tick');
        if (this.notificationService.hasWorker('tasks')) {
            this.notificationService.stopWorker('tasks');
        }
    }

    dispose() {
        this.clearTasks();
    }
}
