import { TaskStatus } from '../enums/TaskStatus';
import { TaskType } from '../enums/TaskType';
import { ProviderDetails } from '../models';

export interface TaskPayload {
    provider: ProviderDetails;
}

export interface TaskErrorMessage {
    error?: string;
    status?: TaskStatus;
    provider?: ProviderDetails;
    message?: string;
}

export interface TasksPaginatedResponse<T extends TaskEntity = TaskEntity> {
    totalTasks: number;
    totalPages: number;
    tasks: T[];
}

export class TaskEntity {
    id: number;
    type: TaskType;
    name: string;
    status: TaskStatus;
    runAt: Date;
    latestAttempt: Date;
    errorMessage?: TaskErrorMessage[];
    payload: TaskPayload;

    children?: TaskEntity[];
    parentTask?: TaskEntity;

    getChildrenStatus(): TaskStatus {
        if (this.children?.some((child) => child.status === TaskStatus.ERROR)) {
            return TaskStatus.ERROR;
        }
        if (this.children?.some((child) => child.status === TaskStatus.RUNNING)) {
            return TaskStatus.RUNNING;
        }
        if (this.children?.some((child) => child.status === TaskStatus.PENDING)) {
            return TaskStatus.PENDING;
        }
        if (this.children?.some((child) => child.status === TaskStatus.CANCEL)) {
            return TaskStatus.CANCEL;
        }
        if (this.children?.some((child) => child.status === TaskStatus.SUCCESS)) {
            return TaskStatus.SUCCESS;
        }
        return TaskStatus.ERROR;
    }

    getChildrenLastRunAt(): Date | undefined {
        const firstChild = this.children?.find((child) => child.runAt !== null && child.runAt !== undefined);
        if (firstChild) {
            return firstChild.runAt;
        }
        return undefined;
    }

    getChildrenLastAttempt(): Date | undefined {
        const firstChild = this.children?.find((child) => child.latestAttempt !== null && child.latestAttempt !== undefined);
        if (firstChild) {
            return firstChild.latestAttempt;
        }

        return;
    }

    getChildrenStatusCount(status: TaskStatus): number {
        const childrenWithStatus = this.children?.filter((child) => child.status === status);
        return childrenWithStatus?.length || 0;
    }

    hasChildrenToRetry(): boolean {
        return this.getChildrenStatusCount(TaskStatus.ERROR) > 0;
    }

    getChildrenToRetry(): TaskEntity[] {
        return this.children?.filter((child) => child.status === TaskStatus.ERROR) || [];
    }

    isScheduledItem(): boolean {
        return this.type === TaskType.SCHEDULED_ITEM_V2;
    }

    isBulkUpdateParent(): boolean {
        return this.type === TaskType.BULK_ITEM_UPDATE_PARENT_V2;
    }

    isScheduledItemV3Parent(): boolean {
        return this.type === TaskType.SCHEDULED_ITEM_V3_PARENT;
    }

    isBulkUpdateV3Parent(): boolean {
        return this.type === TaskType.BULK_ITEM_UPDATE_PARENT_V3;
    }
}

export class ParentTask extends TaskEntity {
    childrenStats: {
        error: number;
        cancel: number;
        pending: number;
        success: number;
        running: number;
        queued: number;
        total: number;
    };
}
