import { WorkerMessage, WorkerInterface } from './WorkerInterface';

export interface CommandInterface {
    execute(): Promise<unknown>;
}

export class CommandIntervalWorker implements WorkerInterface {
    onMessage?: (event: WorkerMessage) => void;
    private intervalRes: any;

    constructor(private readonly interval: number, public cmd: CommandInterface, private triggerOnStart: boolean = true) {}

    start() {
        this.intervalRes = setInterval(() => {
            this.tick();
        }, this.interval);

        if (this.triggerOnStart) {
            this.tick();
        }
    }

    async tick() {
        try {
            const result = await this.cmd.execute();
            if (this.onMessage) {
                this.onMessage(new WorkerMessage(result));
            }
        } catch (e) {
            if (this.onMessage) {
                this.onMessage(new WorkerMessage(e, 'error'));
            }
        }
    }

    stop() {
        clearInterval(this.intervalRes);
    }
}
