export enum LookupType {
    TAXES = 'Taxes',
    DISCOUNTS = 'Discounts',
    CLASSIFICATIONS = 'Classifications',
    CLASSIFICATIONS_WITH_STATUS = 'ClassificationsWithStatus',
    SPECIAL_ACTION_TYPES = 'SpecialActionAvailabilities',
    UNITS = 'UnitsOfMeasure',
    ROUNDING = 'RoundingPrecisions',
    PRICING_CHANGE_POLICY = 'PricingChangePolicies',
    DISPENSING_ITEMS = 'DispensingItems',
    ITEM_TYPES = 'ItemTypes',
    SUPPLIERS = 'Suppliers',
    ANALYSIS_GROUPS = 'AnalysisGroups',
}
