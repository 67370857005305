import { Inject, Service } from 'typedi';
import { BaseHttp, Transformers } from '@enterprise/common';
import { ParentTask, TaskEntity, TasksPaginatedResponse } from '../core/entity/TaskEntity';
import { TaskType } from '../core/enums/TaskType';
import { TaskStatus } from '../core/enums/TaskStatus';
import { first } from 'lodash';

export interface TasksQuery {
    searchValue?: string;
    page?: number;
    pageSize?: number;
    sortModel?: {
        field: string;
        sort: 'asc' | 'desc' | null | undefined;
    }[];
}

export interface ChildrenTasksQuery extends TasksQuery {
    parentTaskId: string;
}

@Service()
export class TaskService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    getParentTasks(query: TasksQuery = {}): Promise<TasksPaginatedResponse<ParentTask>> {
        const { searchValue, page, pageSize, sortModel } = query;
        const { field, sort } = first(sortModel) || {};
        return this.http
            .get('/v2/tasks', {
                params: {
                    searchValue,
                    page,
                    limit: pageSize,
                    sortField: field,
                    sortDirection: sort,
                    type: 'parent',
                },
            })
            .then((data) => ({
                ...data,
                tasks: new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(TaskEntity)).transform(data.tasks),
            }));
    }

    async getDetailedTask(query: ChildrenTasksQuery): Promise<ParentTask | undefined> {
        const { parentTaskId, page = undefined, pageSize = 100 } = query;

        const [parents, children] = await Promise.all<TasksPaginatedResponse<ParentTask>, TasksPaginatedResponse>([
            this.http.get('/v2/tasks', { params: { type: 'parent', ids: [parentTaskId] } }),
            this.http.get(`/v2/tasks`, { params: { parentId: parentTaskId, page, limit: pageSize } }),
        ]);

        const parent = first(parents.tasks);
        return parent && new Transformers.ToTypeTransformer(ParentTask).transform({ ...parent, children: children.tasks });
    }

    getTasks(ids?: number[]): Promise<TaskEntity[]> {
        return this.http
            .get(`/tasks`, {
                params: {
                    ids,
                    types: [
                        TaskType.SCHEDULED_ITEM_V2,
                        TaskType.BULK_ITEM_UPDATE_PARENT_V2,
                        TaskType.BULK_ITEM_UPDATE_PARENT_V3,
                        TaskType.SCHEDULED_ITEM_V3_PARENT,
                        TaskType.INDEX_SYNC_V3_PARENT,
                    ],
                },
            })
            .then((data) => new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(TaskEntity)).transform(data));
    }

    runTasks(ids: number[]): Promise<void> {
        return this.http.post(`/tasks/run`, null, { params: { ids } });
    }

    runFailedTasks(parentId: number, status: TaskStatus = TaskStatus.ERROR): Promise<number[]> {
        return this.http.post(`/v2/tasks/run`, { parentId, status });
    }

    cancelTasks(ids: number[]): Promise<void> {
        return this.http.put(`/tasks/cancel`, { ids });
    }
}
