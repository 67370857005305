import { observer } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import { Cmp, UserRoles } from '@enterprise/common';
import { SpotLoading } from '@enterprise/spot';
import { NavLink, Route } from 'react-router-dom';
import { BulkUpdateDetailsPage } from './BulkUpdateDetailsPage';
import moment from 'moment';
import { environment } from '../../../environment/environment';
import { TaskStatus } from '../../../core/enums/TaskStatus';
import { ParentTask, TaskEntity } from '../../../core/entity/TaskEntity';
import i18n from '../../../i18n';
import classNames from 'classnames';
import styles from './ActivityPage.module.scss';
import { Trans } from 'react-i18next';
import { useStores } from '../../../hooks';
import React, { useEffect, useState } from 'react';
import { RouterPaths } from '../../../router/RouterPaths';
import { CancelTaskModal, CancelTaskModalResponse } from './cancelTaskModal';
import { GridCellParams, GridColDef, GridRowData, GridSortModel, MaterialDataGrid, GridValueGetterParams } from '@enterprise/material-data-grid';
import { PermissionLevel } from '../../../core/enums/permissionLevel';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { RootRoutes } from '../../../router';
import { SearchBar } from '../../../components/mui/searchBar';
import { GridColumnHeaderParams } from '@mui/x-data-grid/models/params/gridColumnHeaderParams';

const DEFAULT_PAGE_SIZE = 25;

enum GridColumns {
    Description = 'Description',
    Status = 'Status',
    Date = 'Date',
}

const headerCell = (params: GridColumnHeaderParams): React.ReactElement => {
    const header = params.colDef.headerName || params.colDef.field || '';

    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)} data-automation-id="activity-center">
                {header}
            </div>
        </div>
    );
};

export const ActivityPage = observer(function ActivityPageComponent() {
    const {
        domain: { activityPageStore, usersStore },
        ui: { app },
    } = useStores();

    const { token } = app;
    const { isLoaded, isTaskLoading, parentTasks, gridState = {}, cancelInProgress, runFailedTasksRequest } = activityPageStore;
    const { pageSize = DEFAULT_PAGE_SIZE, page = 0, sortModel = [{ field: 'run_at', sort: 'desc' }] } = gridState;
    const { isLoadingUserAccess, userPracticesAccess = {} } = usersStore;
    const isLoading = !isLoaded || isLoadingUserAccess;
    const [searchValue, setSearchValue] = useState('');
    const [cancelItemId, setCancelItemId] = useState<number>();
    const hasAccess =
        token.hasRole(UserRoles.SystemOwner) ||
        token.hasRole(UserRoles.SuperAdmin) ||
        usersStore.userPermissionLevel === PermissionLevel.DecisionMaker;
    const hasAllPracticesAccess = hasAccess && !userPracticesAccess.practices?.length && !userPracticesAccess.regions?.length;
    const rowHeight = 90;

    useEffect(() => {
        void activityPageStore.load();
        void usersStore.fetchUserAccess(token.user.id);

        return () => {
            activityPageStore.clearTasks();
        };
    }, []);

    const onFilterChanged = (value: string) => {
        setSearchValue(value);
    };

    const cancelTask = (action: CancelTaskModalResponse): void => {
        if (action === CancelTaskModalResponse.Yes && cancelItemId) {
            void activityPageStore.cancelTasks([cancelItemId]);
        }

        setCancelItemId(undefined);
    };

    useEffect(() => {
        const value = searchValue && searchValue.trim();
        void activityPageStore.fetchParentTasks({ searchValue: value, page, pageSize, sortModel }, { delay: 1000 });
    }, [searchValue, page, pageSize, JSON.stringify(sortModel)]);

    const rows = parentTasks?.tasks || [];

    const descriptionCell = (params: GridCellParams): React.ReactElement => {
        const task = params.row as ParentTask;

        return (
            <div className={classNames('spot-typography__text--body', styles.rowItem)}>
                <NavLink
                    className={classNames('spot-link', styles.name)}
                    to={`${RouterPaths.ControlCenterPages.Activity}/bulk/${task.id}`}
                    data-automation-id={'activity-item-name'}
                >
                    <span>{task.name}</span>
                </NavLink>
                <p className={'spot-typography__text--tertiary'} data-automation-id={'activity-total-items'}>
                    {task.childrenStats?.total} items
                </p>
            </div>
        );
    };

    const statusCell = (params: GridCellParams): React.ReactElement => {
        const task = params.row as ParentTask;
        const { description, className, count, isError, status } = getParentDescription(task) || {};
        const runAllFailed = () => {
            return activityPageStore.runAllFailedChildTasks(task.id);
        };

        return (
            <div className={classNames('spot-typography__text--body', styles.activityStatusRow)} data-automation-id="activity-center">
                <div className={classNames('spot-typography__text--body', styles.status)}>
                    <span className={classNames('spot-pill', className, styles.statusPill)} data-automation-id={'activity-item-status'}>
                        {description}
                    </span>
                    <p className={'spot-typography__text--tertiary'} data-automation-id={'activity-item-status-total-items'}>
                        {count} Items
                    </p>
                    {isError && hasAccess && hasAllPracticesAccess && (
                        <span className={classNames(styles.action)}>
                            {Boolean(runFailedTasksRequest[task.id]) && <SpotLoading size={'small'} />}
                            <NavLink
                                to={'#'}
                                className={classNames(classNames('spot-link', { ['spot-link--disabled']: Boolean(runFailedTasksRequest[task.id]) }))}
                                onClick={runAllFailed}
                                data-automation-id="activity-run-all-failed"
                            >
                                <Trans i18nKey="activityPage:runAllFailed">Run All Failed</Trans>
                            </NavLink>
                        </span>
                    )}
                    {(status === TaskStatus.PENDING || status === TaskStatus.INITIALIZATION) && hasAllPracticesAccess && (
                        <span className={classNames(styles.action)}>
                            {Boolean(cancelInProgress[task.id]) && <SpotLoading size={'small'} />}
                            <NavLink
                                to={'#'}
                                className={classNames('spot-link', { ['spot-link--disabled']: Boolean(cancelInProgress[task.id]) })}
                                onClick={() => setCancelItemId(task.id)}
                                data-automation-id="cancel-bulk-update"
                            >
                                <Trans i18nKey="activityPage:cancel">Cancel</Trans>
                            </NavLink>
                        </span>
                    )}
                </div>
            </div>
        );
    };

    const dateCell = (params: GridCellParams): React.ReactElement => {
        const task = params.row as ParentTask;
        const { date } = getParentDescription(task) || {};

        return (
            <div className={classNames('spot-typography__text--body')} data-automation-id="activity-center">
                <div className="spot-typography__text--body">
                    <span data-automation-id={'activity-item-date'} className={classNames('spot-typography__font-weight--bold', styles.date)}>
                        {moment(date).format(environment.dateFormat)}
                    </span>
                </div>
            </div>
        );
    };

    const getParentDescription = (
        task: ParentTask,
    ): { description: string; date?: Date; className: string; count?: number; isError?: boolean; status: TaskStatus } | undefined => {
        const { pending = 0, running = 0, queued = 0, error = 0, success = 0, cancel = 0, total } = task.childrenStats || {};
        switch (task.status) {
            case TaskStatus.INITIALIZATION:
                return {
                    description: i18n.t('activityPage:pending', 'Pending'),
                    count: total || pending,
                    date: task.runAt,
                    className: 'spot-pill--warning',
                    status: task.status,
                };
            case TaskStatus.PENDING:
                return {
                    description: i18n.t('activityPage:pending', 'Pending'),
                    count: pending,
                    date: task.runAt,
                    className: 'spot-pill--warning',
                    status: task.status,
                };
            case TaskStatus.RUNNING:
                return {
                    description: i18n.t('activityPage:running', 'Running'),
                    count: running + queued,
                    date: task.runAt,
                    className: 'spot-pill--warning',
                    status: task.status,
                };
            case TaskStatus.SUCCESS:
                return {
                    description: task.childrenStats?.total ? i18n.t('activityPage:success', 'Success') : i18n.t('activityPage:pending', 'Pending'),
                    count: success,
                    date: task.latestAttempt || task.runAt,
                    className: task.childrenStats?.total ? 'spot-pill--positive' : 'spot-pill--warning',
                    status: task.childrenStats?.total ? task.status : TaskStatus.PENDING,
                };
            case TaskStatus.ERROR:
                return {
                    description: i18n.t('activityPage:error', 'Error'),
                    count: error,
                    date: task.latestAttempt || task.runAt,
                    className: 'spot-pill--negative',
                    isError: true,
                    status: task.status,
                };
            case TaskStatus.CANCEL:
                return {
                    description: i18n.t('activityPage:canceled', 'Canceled'),
                    count: cancel || total,
                    date: task.runAt,
                    className: 'spot-pill--secondary',
                    status: task.status,
                };
        }

        return undefined;
    };

    const getRowId = (row: GridRowData): string => {
        const { id, type } = row as TaskEntity;
        return `${id}-${type}`;
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 2,
            width: 150,
            renderCell: descriptionCell,
            headerName: GridColumns.Description,
            renderHeader: headerCell,
            valueGetter: (parentTask: GridValueGetterParams) => {
                return parentTask.row.name;
            },
        },
        {
            field: 'status',
            width: 110,
            renderCell: statusCell,
            headerName: GridColumns.Status,
            renderHeader: headerCell,
            valueGetter: (parentTask: GridValueGetterParams) => {
                const { description } = getParentDescription(parentTask.row as ParentTask) || {};
                return description;
            },
        },
        {
            field: 'run_at',
            flex: 1,
            minWidth: 160,
            renderCell: dateCell,
            headerName: GridColumns.Date,
            renderHeader: headerCell,
            valueGetter: (parentTask: GridValueGetterParams) => {
                const { date } = getParentDescription(parentTask.row as ParentTask) || {};
                return date;
            },
        },
    ];

    const onPageChange = (newPage: number) => {
        activityPageStore.updateGridState({ page: newPage, pageSize, sortModel });
    };

    const onPageSizeChange = (newPageSize: number, details: GridCallbackDetails) => {
        activityPageStore.updateGridState({ page, pageSize: newPageSize, sortModel });
    };

    const onSortModelChange = (newSortModel: GridSortModel) => {
        activityPageStore.updateGridState({ page, pageSize, sortModel: newSortModel });
    };

    return (
        <>
            <Cmp.If cond={isLoading}>
                <SpotLoading text="Loading..." />
            </Cmp.If>
            <Cmp.If cond={!isLoading}>
                <RootRoutes>
                    <Route path={`${RouterPaths.ControlCenterPages.Activity}/bulk/:id`} element={<BulkUpdateDetailsPage />} />
                </RootRoutes>
                <Container className={classNames('list', styles.activityPage)}>
                    <Row>
                        <Col xs={12} data-automation-id={'activity-search-bar'}>
                            <SearchBar
                                isSelfControlled={false}
                                value={searchValue}
                                onChange={(value) => onFilterChanged(value)}
                                onSubmit={(value) => onFilterChanged(value)}
                            />
                        </Col>
                    </Row>

                    <MaterialDataGrid
                        className={styles.grid}
                        loading={isTaskLoading}
                        columns={columns}
                        rows={rows}
                        rowHeight={rowHeight}
                        getRowClassName={() => styles.row}
                        headerHeight={68}
                        getRowId={getRowId}
                        hideFooterPagination={false}
                        hideFooter={false}
                        pagination
                        rowCount={parentTasks?.totalTasks}
                        page={page}
                        pageSize={pageSize}
                        rowsPerPageOptions={[25, 50, 100, 500]}
                        paginationMode="server"
                        onPageSizeChange={onPageSizeChange}
                        onPageChange={onPageChange}
                        disableColumnFilter={false}
                        disableColumnResize={false}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        classes={{
                            columnHeadersInner: styles.columnHeadersInner,
                            columnHeader: styles.columnHeader,
                            columnSeparator: styles.columnSeparator,
                            columnHeaders: styles.columnHeaders,
                        }}
                    />
                </Container>
            </Cmp.If>
            <CancelTaskModal opened={Boolean(cancelItemId)} onChange={cancelTask} />
        </>
    );
});
