import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Form, FormType, FormRenderProps } from '@enterprise/common';
import { SpotButton } from '@enterprise/spot';
import classNames from 'classnames';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { RightSidebar } from '../../../../../layout/main/rightSidebar';
import { UpdateSummary } from '../updateSummary';
import { ItemAddPractices, SelectionGridState } from '../ItemAddPracticeBulkUpdate';
import styles from './bulkUpdateFlyover.module.scss';
import { InvoiceItem, InvoiceItemWithVariantDetail } from 'apps/client/src/core/models';
import { DatasourceFullSiteInfo } from 'apps/client/src/core/models/datasource/sites';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { PracticeInfo, SiteEntityInfo } from '../../../../practiceConnections/MyOrganizationPageStore';
import { LabelEntity } from '../../../../../core/entity/LabelEntity';
import { useStores } from '../../../../../hooks';
import { ScheduledItemSaveModal } from '../../modals/ScheduledItemSaveModal';
import { BulkUpdateFlyoverType } from '../../ItemsPageStore';
import { Dictionary } from 'async';
import { SelectionModel } from '../../../../../store/invoiceItem.store';

interface BulkUpdateValues {
    updateAt?: Date;
    name?: string;
}

interface BulkUpdateFlyoverProps<TValue, TEntity extends BulkUpdateValues> {
    onSubmit: (params: { update: TEntity; selectedPractices?: PracticeInfo[] }) => void;
    onClose: () => void;
    formType: FormType<TValue, TEntity>;
    fieldset?: any;
    defaultData?: TValue;
    defaultName?: string;
    type: string;
    selectedItems: InvoiceItemWithVariantDetail[];
    sites: DatasourceFullSiteInfo[];
    practicesSelection: SelectionGridState;
    onChange: (selection: SelectionGridState) => void;
    processedCount: number;
    groupedItems: Dictionary<InvoiceItem[]>;
    selectionModel?: SelectionModel;
}

export const BulkUpdateFlyover = observer(function BulkUpdateFlyover<TValue extends BulkUpdateValues, TEntity extends BulkUpdateValues>(
    props: BulkUpdateFlyoverProps<TValue, TEntity>,
) {
    const {
        domain: { practicesStore, itemsPageStore, invoiceItemStore },
    } = useStores();

    const {
        defaultData,
        formType,
        fieldset,
        type,
        onSubmit,
        onClose,
        practicesSelection,
        onChange,
        selectedItems,
        selectionModel,
        processedCount,
        groupedItems,
    } = props;
    const [step, setStep] = useState(0);
    const [practicesSelected, setPracticesSelected] = useState(true);

    const { practices, regions } = useMemo(() => {
        const { labels = [] } = practicesStore;
        const unfilteredPractices: SiteEntityInfo[] = practicesStore.practices as SiteEntityInfo[];
        const activePractices = unfilteredPractices.filter((p: SiteEntityInfo) => p.isActive);

        const filterPractices = (): PracticeInfo[] => {
            const filtered: PracticeInfo[] = [];
            for (const practice of activePractices) {
                for (const item of selectedItems) {
                    const found = item.providers.find(function (p) {
                        return p.siteId === practice.id;
                    });
                    if (found) {
                        filtered.push(practice);
                    }
                }
            }
            return filtered.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        };

        // Filter out only regions that have practices in the list.
        const filterRegions = (includedPractices) => {
            const filtered: LabelEntity[] = [];
            for (const region of labels) {
                for (const practiceId of region.practices) {
                    const found = includedPractices.find(function (e) {
                        return e.rowId === practiceId;
                    });
                    if (found) {
                        filtered.push(region);
                        break;
                    }
                }
            }
            return filtered;
        };

        const practices = filterPractices();
        const regions = filterRegions(practices);

        return { practices, regions };
    }, [selectedItems]);

    useEffect(() => {
        if (practicesSelection.selectAll && practices) {
            onChange({ ...practicesSelection, selectedPractices: practices });
        }
    }, [practices]);

    const submit = (values: TEntity) => {
        onSubmit({ update: { ...values } });
    };

    const renderTitle = () => {
        const updateNameString =
            (type === BulkUpdateFlyoverType.Description && i18n.t('controlCenter.import.practiceDescription', 'Practice Description')) ||
            (type === BulkUpdateFlyoverType.Classification &&
                !itemsPageStore.isAnimanaOnly &&
                i18n.t('controlCenter.import.classification', 'Classification')) ||
            (type === BulkUpdateFlyoverType.Classification &&
                itemsPageStore.isAnimanaOnly &&
                i18n.t('controlCenter.import.productGroup', 'Product Group')) ||
            (type === BulkUpdateFlyoverType.Price && i18n.t('controlCenter.import.price', 'Pricing')) ||
            (type === BulkUpdateFlyoverType.Status && i18n.t('controlCenter.import.status', 'Status'));

        switch (step) {
            case 1: {
                return (
                    <div>
                        <h3>
                            {i18n.t('controlCenter.import.update', 'Update')} {updateNameString}
                        </h3>
                        <h2>{i18n.t('controlCenter.import.updateAtThesePractices', 'Update at These Practices')}</h2>
                    </div>
                );
            }
            case 2: {
                return (
                    <div>
                        <h3>
                            {i18n.t('controlCenter.import.update', 'Update')} {updateNameString}
                        </h3>
                        <h2>{i18n.t('controlCenter.import.reviewYourUpdate', 'Review Your Update')}</h2>
                    </div>
                );
            }
        }
        return (
            <div>
                <h3>
                    {i18n.t('controlCenter.import.update', 'Update')} {updateNameString}
                </h3>
                <h2>
                    {type === BulkUpdateFlyoverType.Description && i18n.t('controlCenter.import.setPracticeDescription', 'Set Practice Description')}
                    {type === BulkUpdateFlyoverType.Classification &&
                        !itemsPageStore.isAnimanaOnly &&
                        i18n.t('controlCenter.import.selectClassification', 'Select Classification')}
                    {type === BulkUpdateFlyoverType.Classification &&
                        itemsPageStore.isAnimanaOnly &&
                        i18n.t('controlCenter.import.selectProductGroup', 'Product Group')}
                    {type === BulkUpdateFlyoverType.Price && i18n.t('controlCenter.import.setPricingRules', 'Set Pricing Rules')}
                    {type === BulkUpdateFlyoverType.Status && i18n.t('controlCenter.import.setStatus', 'Set Status')}
                </h2>
            </div>
        );
    };

    const next = () => {
        if (step === 1 && !practicesSelection.selectedPractices?.length) {
            setPracticesSelected(false);
            return;
        } else {
            setPracticesSelected(true);
        }

        if (step === 2) {
            return;
        }

        setStep(step + 1);
    };

    const back = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const createFooter = (props: FormRenderProps<TValue>) => {
        const { handleSubmit, invalid, form, values } = props;
        return (
            <div>
                <div
                    className={classNames({
                        [styles.footerStyles]: step === 2,
                        [styles.footerStylesSingleButton]: step === 0,
                        [styles.footerStylesSelectPracticeButtons]: step === 1,
                    })}
                >
                    {step === 2 && (
                        <>
                            <div className={styles.bulkUpdateCancelButton} onClick={onClose}>
                                Cancel
                            </div>
                            <div className={styles.footerButtons}>
                                <SpotButton
                                    className={styles.footerButtonStyle}
                                    disabled={invalid}
                                    onClick={() => {
                                        back();
                                    }}
                                    data-automation-id={'bulk-update-previous-button'}
                                >
                                    <span className={styles.bulkUpdateFooterButton}>
                                        <Trans key="common:previous">Previous</Trans>
                                    </span>
                                </SpotButton>

                                <ScheduledItemSaveModal
                                    disabled={invoiceItemStore.isLoadingItemsStats || invalid}
                                    defaultName={values.name}
                                    className={styles.footerButtonStyle}
                                    onSave={({ name, date }) => {
                                        form.change('updateAt', date);
                                        if (name) {
                                            form.change('name', name);
                                        }

                                        handleSubmit();
                                    }}
                                    title={i18n.t('controlCenter:import.scheduleBulkItemUpdate', 'Schedule bulk items update')}
                                />
                                <SpotButton
                                    isPrimary={true}
                                    disabled={invoiceItemStore.isLoadingItemsStats || invalid}
                                    onClick={handleSubmit}
                                    className={styles.footerButtonStyle}
                                >
                                    <span className={styles.bulkUpdateFooterButton}>
                                        <Trans key="common:publish_now">Publish Now</Trans>
                                    </span>
                                </SpotButton>
                            </div>
                        </>
                    )}
                    {step === 0 && (
                        <>
                            <div className={styles.bulkUpdateCancelButtonSelectPractices} onClick={onClose}>
                                <div className={styles.bulkUpdateCancelButtonText}>Cancel</div>
                            </div>
                            <div className={styles.footerButtonsSelectPractices}>
                                <SpotButton
                                    className={styles.footerButtonStyle}
                                    isPrimary={true}
                                    disabled={invalid}
                                    onClick={next}
                                    data-automation-id={'bulk-update-next-button'}
                                >
                                    <span className={styles.bulkUpdateFooterButton}>
                                        <Trans key="common:next">Next</Trans>
                                    </span>
                                </SpotButton>
                            </div>
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <div className={styles.bulkUpdateCancelButtonSelectPractices} onClick={onClose}>
                                Cancel
                            </div>
                            <div className={styles.footerButtonsSelectPractices}>
                                <SpotButton
                                    className={styles.footerButtonStyle}
                                    disabled={invalid}
                                    onClick={() => {
                                        back();
                                    }}
                                    data-automation-id={'bulk-update-previous-button'}
                                >
                                    <span className={styles.bulkUpdateFooterButton}>
                                        <Trans key="common:previous">Previous</Trans>
                                    </span>
                                </SpotButton>
                                <SpotButton
                                    className={styles.footerButtonStyle}
                                    isPrimary={true}
                                    disabled={invalid}
                                    onClick={next}
                                    data-automation-id={'bulk-update-next-button'}
                                >
                                    <span className={styles.bulkUpdateFooterButton}>
                                        <Trans key="common:next">Next</Trans>
                                    </span>
                                </SpotButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <RightSidebar title={renderTitle()} close={onClose} hideBackdrop={true} isSmall={true}>
            <Form data={defaultData} formType={formType} onSubmit={submit} className={styles.bulkUpdateMainFormStyles}>
                {(props: FormRenderProps<TValue>) => {
                    const { values, form } = props;
                    return (
                        <form id="bulkUpdateForm" className={styles.bulkUpdateSubFormStyles}>
                            <Container
                                className={classNames({
                                    [styles.bulkUpdateMainContainerStyleSingleButton]: step === 0,
                                    [styles.bulkUpdateMainContainerStyleSelectPractice]: step === 1,
                                    [styles.bulkUpdateMainContainerStyle]: step === 2 && !Boolean(values.updateAt),
                                    [styles.bulkUpdateMainContainerStyleWithDate]: step === 2 && Boolean(values.updateAt),
                                })}
                            >
                                {step === 2 && (
                                    <Row>
                                        <Col xs={12}>
                                            <UpdateSummary
                                                selectionGridState={practicesSelection}
                                                setName={(name) => form.change('name', name)}
                                                data={values}
                                                type={type}
                                                selectedItems={selectedItems}
                                                selectionModel={selectionModel}
                                                processedCount={processedCount}
                                                groupedItems={groupedItems}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {step === 1 && (
                                    <Row>
                                        <Col xs={12}>
                                            <ItemAddPractices
                                                practices={practices}
                                                regions={regions}
                                                onChange={(gridState) => {
                                                    onChange(gridState);
                                                }}
                                                showError={!practicesSelected}
                                                selectionGridState={practicesSelection}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {step === 0 && (
                                    <Row>
                                        <Col xs={12}>
                                            {React.isValidElement(fieldset)
                                                ? React.cloneElement(fieldset, values)
                                                : React.createElement(fieldset as any, values)}
                                        </Col>
                                    </Row>
                                )}
                            </Container>
                            <Container>
                                <Row>
                                    <Col xs={12}>{createFooter(props)}</Col>
                                </Row>
                            </Container>
                        </form>
                    );
                }}
            </Form>
        </RightSidebar>
    );
});
