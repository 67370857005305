import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SpotDropdown, SpotLoading } from '@enterprise/spot';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import { ItemEditPage } from './ItemEditPage';
import { ItemCard } from '../components/ItemCard';
import { ItemSearchPanel } from './ItemsSearchPanel';
import i18n from '../../../i18n';
import { GetItemQuery, InvoiceItem, InvoiceItemWithVariantDetail } from '../../../core/models';
import { InvoiceItemEditType } from './InvoiceItemEditType.enum';
import { useStores } from '../../../hooks';
import { BulkUpdateModal } from './modals/BulkUpdateModal';
import styles from './ItemsPage_Legacy.module.scss';
import { BulkUpdateFlyoverType } from './ItemsPageStore';
import { RootRoutes } from '../../../router';
import { RouterPaths } from '../../../router/RouterPaths';

export const ItemsPage_Legacy = observer(function ItemsPage() {
    const {
        domain: { itemsPageStore, constantsStore },
        ui: { router },
    } = useStores();
    const [bulkModalType, setBulkModalType] = useState<BulkUpdateFlyoverType>();
    const { invoiceItems, isLoadingItems: isLoadingItemsLegacy } = itemsPageStore;
    const { isLoading: isConstantsLoading } = constantsStore;
    const isLoading = isLoadingItemsLegacy || isConstantsLoading;

    useEffect(() => {
        void itemsPageStore.load();
    });

    const searchItems = async (query: GetItemQuery): Promise<void> => {
        await itemsPageStore.fetchInvoiceItems(query);
    };

    const onEditLegacyHandler = (params: { item?: InvoiceItem; editType?: InvoiceItemEditType }) => {
        const { item, editType = InvoiceItemEditType.Form } = params;

        if (!item) {
            return;
        }

        const path = itemsPageStore.getEditUrl(item, editType);
        router.push(path);
    };

    const createItemsActionPanel = () => {
        return (
            <Row className={styles.actionHeader}>
                <Col xs={10}>
                    <a className={styles.actionsListTitle} onClick={() => itemsPageStore.selectAllItems()}>
                        {!itemsPageStore.areAllItemsSelected()
                            ? i18n.t('controlCenter:itemSearch.selectAll')
                            : i18n.t('controlCenter:itemSearch.deselectAll')}
                    </a>
                </Col>
                <Col xs={2}>
                    <SpotDropdown
                        title="Take Action"
                        titleClassName={styles.actionsListTitle}
                        elements={[
                            { id: BulkUpdateFlyoverType.Price, value: i18n.t('controlCenter:itemSearch.updatePrice') },
                            { id: BulkUpdateFlyoverType.Status, value: i18n.t('controlCenter:itemSearch.updateStatus') },
                        ]}
                        onChange={(id) => setBulkModalType(id)}
                        wrapText={false}
                    />
                </Col>
            </Row>
        );
    };

    const onItemSelected = (item: InvoiceItemWithVariantDetail) => {
        itemsPageStore.selectItem(item);
    };

    const onBulkUpdateModelClose = (props: { reason: 'action' | 'cancel' }) => {
        setBulkModalType(undefined);

        if (props.reason === 'action') {
            itemsPageStore.clearSelectedItems();
        }
    };

    return (
        <div className="items-sub-page">
            <RootRoutes>
                <Route path={`${RouterPaths.ControlCenterPages.Items}/:action`} element={<ItemEditPage />} />
            </RootRoutes>

            <Container className="list" fluid={true}>
                <ItemSearchPanel searchItems={searchItems} />

                {itemsPageStore.isAnyItemSelected() && createItemsActionPanel()}
                <Row data-automation-id={'items-search-results'}>
                    <Col xs={12}>
                        {isLoading && <SpotLoading />}
                        {invoiceItems.map((item) => (
                            <ItemCard
                                key={item.pimsGeneratedId}
                                item={item}
                                onEditClicked={onEditLegacyHandler}
                                isSelected={itemsPageStore.isSelected(item)}
                                onSelected={onItemSelected}
                                constantStore={constantsStore}
                            />
                        ))}
                    </Col>
                </Row>
            </Container>
            <BulkUpdateModal
                type={bulkModalType}
                selectedItems={itemsPageStore.selectedItemsValue}
                invoiceItems={itemsPageStore.uniqueItemsForBulkUpdate}
                onClose={onBulkUpdateModelClose}
            />
        </div>
    );
});
