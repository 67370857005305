import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';
import { BulkUpdateInvoiceItem, GetItemQuery, InvoiceItem, InvoiceItemWithVariantDetail } from '../../../../core/models';
import { BulkPriceEditFormType, DeltaUpdateModel, PriceDeltaUpdateEntity } from '../formType/BulkPriceEditFormType';
import { BulkStatusEditFormType, BulkStatusEditInfo, BulkStatusEditModel } from '../formType/BulkStatusEditFormType';
import {
    BulkUpdateFlyover,
    ClassificationEditFormFieldset,
    PricingEditFormFieldset,
    SelectionGridState,
    StatusEditFormFieldset,
} from '../bulkUpdates';
import { MARKUP_TYPE_UP, PRICE_TYPE_BASE } from '../bulkUpdates/constants';
import { BulkUpdateFlyoverType } from '../ItemsPageStore';
import { BulkPatchEditFormType, PatchUpdateModel } from '../formType/bulkPatchEditFormType';
import { DescriptionEditFormFieldset } from '../bulkUpdates/fieldsets/descriptionEditFormFieldset';
import { SelectionModel } from '../../../../store/invoiceItem.store';
import { FEATURES } from '../../../../../../../packages/core';
import { ItemsGroupByType } from '../../../../core/models/organization/settings/enum';
import { groupBy } from 'lodash';

type BulkUpdateModalProps = {
    type?: BulkUpdateFlyoverType;
    selectedItems?: InvoiceItemWithVariantDetail[];
    invoiceItems?: InvoiceItem[];
    excludedItems?: InvoiceItem[];
    searchQuery?: GetItemQuery;
    onClose: (props: { reason: 'action' | 'cancel' }) => void;
    selectionModel?: SelectionModel;
};

type CreateBulkUpdateProps = {
    update: PriceDeltaUpdateEntity | BulkStatusEditInfo | PatchUpdateModel;
    type: BulkUpdateFlyoverType;
};

export const BulkUpdateModal = observer(function ItemsPage(props: BulkUpdateModalProps) {
    const {
        ui: { app },
        domain: { itemsPageStore, invoiceItemStore },
    } = useStores();

    const enhancedSearchUiEnabled = app.featureManager.isAvailable(FEATURES.ENHANCED_SEARCH_UI, true);

    const {
        type, //
        selectedItems = [],
        invoiceItems = [],
        onClose,
        selectionModel,
        searchQuery,
        excludedItems,
    } = props;
    const { itemsGroupBy } = itemsPageStore;

    const [practicesSelection, setPracticesSelection] = useState<SelectionGridState>({
        selectAll: true,
        selectedPractices: [],
        selectedGridPractices: [],
        selectedGridRegions: [],
        searchTerm: '',
    });

    const { processedItemsCount, processedGroupedItemsCount, groupedItems } = useMemo(() => {
        const selectedPracticesIds = practicesSelection?.selectedPractices?.map(({ id }) => id);
        const groupByKey = itemsGroupBy === ItemsGroupByType.Id ? 'pimsGeneratedId' : 'hospitalDescription';
        const includedItems = invoiceItems.filter((item) => selectedPracticesIds?.includes(item.provider.siteId));
        const gropedIncludedItems = groupBy(includedItems, groupByKey);
        const gropedExcludedItems = groupBy(
            excludedItems?.filter((item) => selectedPracticesIds?.includes(item.provider.siteId)),
            groupByKey,
        );
        const processedGroupedItemsCount = selectionModel?.all
            ? (selectionModel?.groups || 0) - Object.keys(gropedExcludedItems).length
            : Object.keys(gropedIncludedItems).length;

        const processedItemsCount = selectionModel?.all ? (selectionModel?.total || 0) - (excludedItems?.length || 0) : includedItems.length;

        return { processedItemsCount, processedGroupedItemsCount, groupedItems: gropedIncludedItems };
    }, [practicesSelection?.selectedPractices, itemsGroupBy, invoiceItems, excludedItems, selectionModel]);

    const onChange = (selection: SelectionGridState) => {
        setPracticesSelection(selection);

        if (enhancedSearchUiEnabled) {
            const selectedPracticesIds = selection?.selectedPractices?.map(({ id }) => id);
            const itemsSearchQuery: GetItemQuery = { ...(searchQuery || {}), siteId: selectedPracticesIds };
            void invoiceItemStore.fetchBulkUpdateStats(itemsSearchQuery);
        }
    };

    const createBulkUpdate = ({ update, type }: CreateBulkUpdateProps) => {
        if (!practicesSelection.selectedPractices) {
            return;
        }

        const selectedPracticesIds = practicesSelection.selectedPractices.map(({ id }) => id);
        const included = invoiceItems?.map(({ pimsGeneratedId }) => pimsGeneratedId);
        const exluded = excludedItems?.map(({ pimsGeneratedId }) => pimsGeneratedId);
        const itemsSearchQuery: GetItemQuery = { ...(searchQuery || {}), siteId: selectedPracticesIds };

        if (enhancedSearchUiEnabled) {
            void invoiceItemStore.createBulkUpdate({
                includedItems: !selectionModel?.all ? included : undefined,
                excludedItems: selectionModel?.all ? exluded : undefined,
                update,
                type,
                itemsSearchQuery,
                processedItemsCount,
                processedGroupedItemsCount,
            });
        } else {
            void itemsPageStore.createBulkUpdate({
                items: invoiceItems.reduce((agg, item) => {
                    if (selectedPracticesIds.includes(item.provider.siteId)) {
                        agg.push({
                            description: item.hospitalDescription,
                            pimsGeneratedId: item.pimsGeneratedId,
                            provider: item.provider,
                        });
                    }

                    return agg;
                }, [] as BulkUpdateInvoiceItem[]),
                update,
                type,
            });
        }

        onClose({ reason: 'action' });
    };

    return (
        <>
            {(type === BulkUpdateFlyoverType.Description || type === BulkUpdateFlyoverType.Classification) && (
                <BulkUpdateFlyover
                    type={type}
                    fieldset={
                        (type === BulkUpdateFlyoverType.Description && DescriptionEditFormFieldset) ||
                        (type === BulkUpdateFlyoverType.Classification && ClassificationEditFormFieldset) ||
                        undefined
                    }
                    formType={new BulkPatchEditFormType(type)}
                    onSubmit={(params) => createBulkUpdate({ ...params, type })}
                    onClose={() => onClose({ reason: 'cancel' })}
                    practicesSelection={practicesSelection}
                    onChange={onChange}
                    selectedItems={selectedItems}
                    processedCount={processedGroupedItemsCount}
                    groupedItems={groupedItems}
                    selectionModel={selectionModel}
                    sites={itemsPageStore.applications?.filter((app) => app.isActive)}
                />
            )}

            {type === BulkUpdateFlyoverType.Price && (
                <BulkUpdateFlyover
                    type={BulkUpdateFlyoverType.Price}
                    fieldset={PricingEditFormFieldset}
                    formType={new BulkPriceEditFormType()}
                    defaultData={
                        {
                            priceType: PRICE_TYPE_BASE,
                            markupType: MARKUP_TYPE_UP,
                        } as DeltaUpdateModel
                    }
                    onSubmit={(params) => createBulkUpdate({ ...params, type: BulkUpdateFlyoverType.Price })}
                    onClose={() => onClose({ reason: 'cancel' })}
                    practicesSelection={practicesSelection}
                    onChange={onChange}
                    selectedItems={selectedItems}
                    processedCount={processedGroupedItemsCount}
                    groupedItems={groupedItems}
                    selectionModel={selectionModel}
                    sites={itemsPageStore.applications?.filter((app) => app.isActive)}
                />
            )}

            {type === BulkUpdateFlyoverType.Status && (
                <BulkUpdateFlyover
                    type={BulkUpdateFlyoverType.Status}
                    fieldset={StatusEditFormFieldset}
                    formType={new BulkStatusEditFormType()}
                    defaultData={
                        {
                            isActive: '1',
                        } as BulkStatusEditModel
                    }
                    onSubmit={(params) => createBulkUpdate({ ...params, type: BulkUpdateFlyoverType.Status })}
                    onClose={() => onClose({ reason: 'cancel' })}
                    practicesSelection={practicesSelection}
                    onChange={onChange}
                    selectedItems={selectedItems}
                    processedCount={processedGroupedItemsCount}
                    groupedItems={groupedItems}
                    selectionModel={selectionModel}
                    sites={itemsPageStore.applications?.filter((app) => app.isActive)}
                />
            )}
        </>
    );
});
