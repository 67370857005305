export const FEATURES = {
    ANALYTICS: 'ANALYTICS',
    ANALYTICS_PETLY_MODEL: 'ANALYTICS_PETLY_MODEL',
    ANALYTICS_TRANSACTIONS_MODEL: 'ANALYTICS_TRANSACTIONS_MODEL',
    IMPORT: 'IMPORT',
    MASTER_DATA: 'MASTER_DATA',
    CONNECT_PRACTICE: 'CONNECT_PRACTICE',
    CONTROL_CENTER: 'CONTROL_CENTER',
    ANALYTICS_RVETLINK_MODEL: 'ANALYTICS_RVETLINK_MODEL',
    ENHANCED_PRODUCT_SEARCH: 'ENHANCED_PRODUCT_SEARCH',
    ENHANCED_SEARCH_UI: 'ENHANCED_SEARCH_UI',
};
